import { client, flexibleUrlClient } from './client';
import {
  AccountStatsResponseType,
  AccountTimePeriodUsageResponseType,
  AccountStatsDataType,
  AccountGraphStatType,
  AccountUsageType,
  VariantObjectType,
  AccountGraphVariantId,
  NodeGraphVariantIdType,
  GeneralDataNodesResponseType,
  TimePeriodUsageResponseType,
} from './types';

export const fetchGraphDataFromStats = async (
  account: string,
  graphStatId: AccountGraphStatType
): Promise<
  | {
      graphDataSBU: AccountUsageType[];
      graphDataCurrency: AccountUsageType[];
      variants: VariantObjectType[];
    }
  | undefined
> => {
  try {
    const statsResponse: AccountStatsResponseType = await client.get(
      `/accounts/${account}/stats`
    );

    if (statsResponse?.data.length) {
      // find the stat i want by matching stat id with stats string argument
      let foundStat: AccountStatsDataType | undefined = statsResponse.data.find(
        (stat: AccountStatsDataType) => stat.id === graphStatId
      );

      if (foundStat) {
        // if present, make a request to /stats/my-stat
        try {
          const dataFromFoundStatRes: AccountTimePeriodUsageResponseType =
            await flexibleUrlClient.get(foundStat.href);

          if (dataFromFoundStatRes?.data) {
            return {
              graphDataSBU: [...dataFromFoundStatRes.data.sbu_data],
              graphDataCurrency: [...dataFromFoundStatRes.data.currency_data],
              variants: [...dataFromFoundStatRes.data.variants],
            };
          } else {
            console.error('No detailed stats data for this account');
            return;
          }
        } catch (error) {
          console.error(
            'Error fetching detailed stats data for this account: ',
            error
          );
        }
      } else {
        console.error('No desired stat found');
      }
    } else {
      console.error('No stats found for this account');
    }
  } catch (error) {
    console.error('Unable to fetch graphs data from stats: ', error);
  }
};

// fetches graphs (stacked graphs and pie chart) data for accounts
export const fetchGraphDataFromVariant = async (
  account: string,
  graphStatId: AccountGraphStatType,
  variantId: AccountGraphVariantId
): Promise<
  | {
      graphDataSBU: AccountUsageType[];
      graphDataCurrency: AccountUsageType[];
    }
  | undefined
> => {
  try {
    const graphDataFromVariantResponse: AccountTimePeriodUsageResponseType =
      await client.get(`/accounts/${account}/stats/${graphStatId}`, {
        params: { variant_id: variantId },
      });
    return {
      graphDataSBU: [...graphDataFromVariantResponse.data.sbu_data],
      graphDataCurrency: [...graphDataFromVariantResponse.data.currency_data],
    };
  } catch (error) {
    console.error('Failed to fetch graph data from variant', error);
  }
};

// when clicking on a stacked area in node graphs to reveal its children
export const newfetchClickedNodeGraphData = async (
  id: string,
  graphId: 'usage-per-day' | 'total-node-usage',
  variantId: NodeGraphVariantIdType
): Promise<
  | {
      childrenStatsData: TimePeriodUsageResponseType[];
      childrenGeneralData: GeneralDataNodesResponseType[];
    }
  | undefined
> => {
  try {
    const response: GeneralDataNodesResponseType = await client.get(
      `/nodes/${id}`
    );

    if (response?.data?.length) {
      const childrenResPromise: Promise<GeneralDataNodesResponseType>[] =
        response.data[0].children.map((child) =>
          flexibleUrlClient.get(child.href)
        );
      const childrenGeneralData: GeneralDataNodesResponseType[] =
        await Promise.all(childrenResPromise);

      const childrenStatsPromise: Promise<TimePeriodUsageResponseType>[] =
        childrenGeneralData.map((res: GeneralDataNodesResponseType) => {
          return flexibleUrlClient.get(`${res.data[0].stats.href}/${graphId}`, {
            params: { variant_id: variantId },
          });
        });

      const childrenStatsData: TimePeriodUsageResponseType[] =
        await Promise.all(childrenStatsPromise);

      return {
        childrenStatsData,
        childrenGeneralData,
      };
    }
  } catch (error) {
    console.error('Faield to fetch clicked node graph data: ', error);
  }
};
