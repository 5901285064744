// TABLE CONSTANTS
export const READ_ACCESS: string = 'READ ACCESS';
export const ADMIN_ACCESS: string = 'ADMIN ACCESS';
export const GRANT_ACCESS: string = 'Enter a user id';

// ECMWF ADMIN TABLE VARRIANTS
export const ECMWF_ADMIN_MANAGE_NODES_AND_ACCESS =
  'ECMWF ADMIN MANAGE NODES AND ACCESS';
export const ECMWF_ADMIN_MANAGE_OVERALLOCATIONS =
  'ECMWF ADMIN MANAGE OVERALLOCATIONS';
export const ECMWF_ADMIN_CHANGE_LOG = 'ECMWF ADMIN CHANGE LOG';
export const ECMWF_ADMIN_SEARCH_ACCOUNTS = 'ECMWF ADMIN SEARCH ACCOUNTS';

// COMPREP TABLE VARRIANTS
export const COMPREP_MANAGE_ALLOCATIONS = 'COMPREP MANAGE ALLOCATIONS';
export const COMPREP_MANAGE_READ_ACCESS = 'COMPREP MANAGE READ ACCESS';
export const COMPREP_SEARCH_USERS = 'COMPREP SEARCH USERS';
export const COMPREP_SBUS_USAGE_MONTHS = 'COMPREP SBUS USAGE MONTHS';
export const COMPREP_SBUS_USAGE_QUARTERS = 'COMPREP SBUS USAGE QUARTERS';
export const COMPREP_SBUS_USAGE_YEARS = 'COMPREP SBUS USAGE YEARS';
export const COMPREP_EMAIL_ALERT_TABLE = 'COMPREP EMAIL ALERT TABLE';

// ECMWF FINANCE - MANAGER
export const ECMWF_FINANCE_SBUS_USAGE_MONTHS =
  'ECMWF FINANCE SBUS USAGE MONTHS';
export const ECMWF_FINANCE_SBUS_USAGE_QUARTERS =
  'ECMWF FINANCE SBUS USAGE QUARTERS';
export const ECMWF_FINANCE_SBUS_USAGE_YEARS = 'ECMWF FINANCE SBUS USAGE YEARS';

// PPI TABLES
export const PPI_SBUS_USAGE_MONTHS = 'PPI SBUS USAGE MONTHS';
export const PPI_SBUS_USAGE_QUARTERS = 'PPI SBUS USAGE QUARTERS';
export const PPI_SBUS_USAGE_YEARS = 'PPI SBUS USAGE YEARS';

// HPC USER TABLES
export const HPC_USER_SBUS_USAGE = 'HPC_USER_SBUS_USAGE';

// NODE DETAIL VIEW
export const NODE_DETAIL_VIEW_MONTHS = 'NODE DETAIL VIEW MONTHS';
export const NODE_DETAIL_VIEW_QUARTERS = 'NODE DETAIL VIEW QUARTERS';
export const NODE_DETAIL_VIEW_YEARS = 'NODE DETAIL VIEW YEARS';

// ACCOUNT DETAIL VIEW
export const ACCOUNT_DETAIL_VIEW_MONTHS = 'ACCOUNT DETAIL VIEW MONTHS';
export const ACCOUNT_DETAIL_VIEW_QUARTERS = 'ACCOUNT DETAIL VIEW QUARTERS';
export const ACCOUNT_DETAIL_VIEW_YEARS = 'ACCOUNT DETAIL VIEW YEARS';

// Flat table variants (no hierarchical data) which are sortable
export const SORTABLE_TABLE_VARIANTS = [
  ECMWF_ADMIN_CHANGE_LOG,
  ECMWF_ADMIN_SEARCH_ACCOUNTS,
  COMPREP_SEARCH_USERS,
  HPC_USER_SBUS_USAGE,
  ACCOUNT_DETAIL_VIEW_MONTHS,
  ACCOUNT_DETAIL_VIEW_QUARTERS,
  ACCOUNT_DETAIL_VIEW_YEARS,
];

// cacheKeys (used to refresh caches)
export const MY_USAGE = 'MyUsage';
export const READ_ONLY_ACCESS = 'ReadOnlyAccess';
export const NODES_USAGE = 'NodesUsage';
export const MANAGE_ALLOCATIONS = 'ManageAllocations';
export const MANAGE_NODES_ACCESS = 'ManageNodesAccess';
export const MANAGE_OVERALLOCATIONS = 'ManageOverallocations';

// session storage pre-fix
export const PREFIX = 'sbu-accounting-app-';
