import React from 'react';
import { ResponsiveContainer, PieChart, Tooltip, Pie, Cell, Legend } from 'recharts';
import { PieChartProps } from '../types';
import { colorArray } from '../utils';

const UsagePieChart = ({ data, displayUnit }: PieChartProps) => {
  return (
    <ResponsiveContainer width={'100%'} height={430}>
      <PieChart style={{ border: 'none' }}>
        <Legend
          layout={'horizontal'}
          verticalAlign="top"
          align="center"
        />
        <Pie
          isAnimationActive={false}
          data={data}
          labelLine={true}
          label={(entry) => {
            return entry ? entry.pct : '1000%';
          }}
          outerRadius={150}
          animationDuration={500}
          fill='#8884d8'
          dataKey='value'>
          {data.map((entry, index) => (
            <Cell
              key={index}
              fill={colorArray[index]}
            />
          ))}
        </Pie>
        <Tooltip
          contentStyle={{ backgroundColor: 'rgba(0,0,0, 0.7)', borderColor: 'black' }}
          itemStyle={{ color: 'white' }}
          formatter={(value: number, name: string, props: { payload: { pct: string; }; }) => (
            [`${value.toLocaleString()} ${displayUnit} (${props.payload.pct})`, name]
          )}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default UsagePieChart;