import ReactTooltip from 'react-tooltip';
import { colors } from '../../../globalStyles/theme';


interface ISort {
  order: 'ascending' | 'descending' | 'original';
}

const Sort = ({
  order,
}: ISort) => {

  const renderIcon = (order: string) => {
    switch (order) {
      case 'ascending':
        return ascending();
      case 'descending':
        return descending();
      case 'original':
        return original();
      default:
        return original();
    }
  };

  return (<>
    {renderIcon(order)}
    < ReactTooltip
      id='sorted'
      type='dark'
      place="top"
      effect="solid"
      afterShow={() => { setTimeout(ReactTooltip.hide, 3000); }}
    >
      <span>Sorted in {order} order</span>
    </ReactTooltip >
  </>);
};
export default Sort;


const descending = () => (
  <svg width="25" height="17" viewBox="0 0 50 39" fill={colors.black.main} xmlns="http://www.w3.org/2000/svg" id="sorted">
    <path d="M41.2929 38.7071C41.6834 39.0976 42.3166 39.0976 42.7071 38.7071L49.0711 32.3431C49.4616 31.9526 49.4616 31.3195 49.0711 30.9289C48.6805 30.5384 48.0474 30.5384 47.6569 30.9289L42 36.5858L36.3431 30.9289C35.9526 30.5384 35.3195 30.5384 34.9289 30.9289C34.5384 31.3195 34.5384 31.9526 34.9289 32.3431L41.2929 38.7071ZM41 0V38H43V0L41 0Z" fill={colors.white.main} />
    <rect x="20" y="36" width="10" height="2" rx="1" fill={colors.white.main} />
    <rect width="30" height="2" rx="1" fill={colors.white.main} />
    <rect x="15" y="27" width="15" height="2" rx="1" fill={colors.white.main} />
    <rect x="10" y="18" width="20" height="2" rx="1" fill={colors.white.main} />
    <rect x="5" y="9" width="25" height="2" rx="1" fill={colors.white.main} />
  </svg>
);

const ascending = () => {
  const fillColor = colors.white.main;
  return (
    <svg width="25" height="17" viewBox="0 0 50 39" fill={colors.black.main} xmlns="http://www.w3.org/2000/svg" id="sorted">
      <path d="M42.7071 0.292893C42.3166 -0.0976311 41.6834 -0.0976311 41.2929 0.292893L34.9289 6.65685C34.5384 7.04738 34.5384 7.68054 34.9289 8.07107C35.3195 8.46159 35.9526 8.46159 36.3431 8.07107L42 2.41421L47.6569 8.07107C48.0474 8.46159 48.6805 8.46159 49.0711 8.07107C49.4616 7.68054 49.4616 7.04738 49.0711 6.65685L42.7071 0.292893ZM43 39V1H41V39H43Z" fill={fillColor} />
      <rect y="37" width="30" height="2" rx="1" fill={fillColor} />
      <rect x="20" y="1" width="10" height="2" rx="1" fill={fillColor} />
      <rect x="5" y="28" width="25" height="2" rx="1" fill={fillColor} />
      <rect x="10" y="19" width="20" height="2" rx="1" fill={fillColor} />
      <rect x="15" y="10" width="15" height="2" rx="1" fill={fillColor} />
    </svg >
  );
};

const original = () => {
  const fillColor = colors.white.main;
  return (
    <svg width="25" height="17" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.29289 39.7071C7.68342 40.0976 8.31658 40.0976 8.70711 39.7071L15.0711 33.3431C15.4616 32.9526 15.4616 32.3195 15.0711 31.9289C14.6805 31.5384 14.0474 31.5384 13.6569 31.9289L8 37.5858L2.34315 31.9289C1.95262 31.5384 1.31946 31.5384 0.928932 31.9289C0.538408 32.3195 0.538408 32.9526 0.928932 33.3431L7.29289 39.7071ZM7 1L7 39H9L9 1L7 1Z" fill={fillColor} />
      <path d="M28.7071 0.292892C28.3166 -0.0976295 27.6834 -0.0976295 27.2929 0.292892L20.9289 6.65685C20.5384 7.04738 20.5384 7.68054 20.9289 8.07107C21.3195 8.46159 21.9526 8.46159 22.3431 8.07107L28 2.41422L33.6569 8.07107C34.0474 8.46159 34.6805 8.46159 35.0711 8.07107C35.4616 7.68054 35.4616 7.04738 35.0711 6.65685L28.7071 0.292892ZM29 39L29 1H27L27 39H29Z" fill={fillColor} />
    </svg>

  );
};