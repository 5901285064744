import { combineReducers } from 'redux';
import serviceReducer from './service';
import policiesReducer from './policies';
import treeReducer from './tree';
import userReducer from './user';
import userAccountsReducer from './userAccounts';
import nodesReducer from './nodes';
import initialNodesReducer from './initialNodes';
import loginReducer from './login';
import displayUnitReducer from './displayUnit';

const rootReducer = combineReducers({
  service: serviceReducer,
  policies: policiesReducer,
  tree: treeReducer,
  user: userReducer,
  userAccounts: userAccountsReducer,
  initialNodes: initialNodesReducer,
  nodes: nodesReducer,
  isLoggedIn: loginReducer,
  displayUnit: displayUnitReducer,
});

export default rootReducer;
