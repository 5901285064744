import { client, flexibleUrlClient } from './client';
import {
  AccountDataType,
  AccountTimePeriodUsageResponseType,
  AccountUsageType,
  AccountWithGeneralDataResponse,
  SearchAccountResponseType,
  SearchedAccountUsersUsageMetaType,
  SearchedAccountUsersResponseType,
  SearchAccountType,
  SearchUsersResponseType,
  SearchUserType,
} from './types';

type TableStats = 'usage-per-month' | 'usage-per-quarter' | 'usage-per-year';

export const fetchAllAccounts = async (): Promise<
  SearchAccountType[] | undefined
> => {
  try {
    const allAccountsResponse: SearchAccountResponseType = await client.get(
      '/accounts/'
    );

    if (allAccountsResponse?.data.length) {
      return allAccountsResponse.data;
    } else {
      console.error('No accounts found.');
      return;
    }
  } catch (error) {
    console.error('Unable to fetch all accounts: ', error);
    return;
  }
};

export const searchAccountUsersUsage = async (
  searchTerm: string,
  from: Date,
  to: Date
): Promise<SearchedAccountUsersUsageMetaType | undefined> => {
  try {
    const response: SearchedAccountUsersResponseType = await client.get(
      `/accounts/${searchTerm}/stats/account-usage-per-user`,
      {
        params: {
          variant_id: 'custom-timespan',
          from_date: from,
          to_date: to,
        },
      }
    );

    if (response?.data) {
      return response.data;
    } else {
      return;
    }
  } catch (error) {
    console.error('Error fetching user accounts usage: ', error);
    return;
  }
};

export const fetchAccountUsersData = async (
  username: string
): Promise<AccountDataType[] | undefined> => {
  try {
    // user's list accounts response
    const generalUserAccountsResponse: SearchAccountResponseType =
      await client.get('/accounts/');

    if (
      generalUserAccountsResponse &&
      generalUserAccountsResponse.data.length
    ) {
      const accountsWithDataPromise: Promise<
        AccountWithGeneralDataResponse | any
      >[] = generalUserAccountsResponse.data.map(
        async (account: SearchAccountType) => {
          try {
            const accountWithData: AccountWithGeneralDataResponse =
              await flexibleUrlClient.get(account.href);
            return accountWithData;
          } catch (error) {
            console.error('Error fetching account with data: ', error);
          }
        }
      );
      const accountsWithDataResponse: AccountWithGeneralDataResponse[] =
        await Promise.all(accountsWithDataPromise);

      const validAccountsWithData: AccountDataType[] = accountsWithDataResponse
        .filter((acc) => acc)
        .map((acc) => acc.data);

      return validAccountsWithData;
    } else {
      console.error('No data for user accounts');
      return;
    }
  } catch (error) {
    console.error("Unable to fetch user's accounts: ", error);
  }
};

export const fetchUserAccountsData = async (
  username: string
): Promise<AccountDataType[] | undefined> => {
  try {
    // user's list accounts response
    const generalUserAccountsResponse: SearchUsersResponseType =
      await client.get(`/users/${username}/accounts/`);

    if (
      generalUserAccountsResponse &&
      generalUserAccountsResponse.data.length
    ) {
      const accountsWithDataPromise: Promise<
        AccountWithGeneralDataResponse | any
      >[] = generalUserAccountsResponse.data.map(
        async (account: SearchUserType) => {
          try {
            const accountWithData: AccountWithGeneralDataResponse =
              await flexibleUrlClient.get(account.href);
            return accountWithData;
          } catch (error) {
            console.error('Error fetching account with data: ', error);
          }
        }
      );
      const accountsWithDataResponse: AccountWithGeneralDataResponse[] =
        await Promise.all(accountsWithDataPromise);

      const validAccountsWithData: AccountDataType[] = accountsWithDataResponse
        .filter((acc) => acc)
        .map((acc) => acc.data);

      return validAccountsWithData;
    } else {
      console.error('No data for user accounts');
      return;
    }
  } catch (error) {
    console.error('Unable to fetch user\'s accounts: ', error);
  }
};

export const fetchAccountFromId = async (
  accountName: string
): Promise<AccountDataType | undefined> => {
  try {
    const response: AccountWithGeneralDataResponse = await client.get(
      `/accounts/${accountName}`
    );

    return response.data;
  } catch (error) {
    console.error('Unable to fetch account from id: ', error);
  }
};

export const fetchAccountStatData = async (
  accountName: string,
  tableStatId: TableStats
): Promise<
  | {
      graphDataSBU: AccountUsageType[];
      graphDataCurrency: AccountUsageType[];
    }
  | undefined
> => {
  try {
    const response: AccountTimePeriodUsageResponseType = await client.get(
      `/accounts/${accountName}/stats/${tableStatId}`
    );

    return {
      graphDataSBU: response.data.sbu_data,
      graphDataCurrency: response.data.currency_data,
    };
  } catch (error) {
    console.error('Unable to fetch account stat: ', error);
  }
};
